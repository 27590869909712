import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';
import {toast} from "react-toastify";
import ConfirmationModal from "../../utils/ConfirmationModal";
import {useApiService} from "../../context/ApiServiceContext";
import Coupon from "../../models/Coupon";
import DatePicker from "react-datepicker";
import * as yup from 'yup';
import {Formik} from "formik";

const AdminCoupons: React.FC = () => {
    const apiService = useApiService();
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false); // State for Add Modal

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20;
    /* End of Pagination */

    const fetchCoupons = useCallback(async (page = 1) => {
        try {
            const res = await apiService.api.get(`/coupon/list?page=${page}&per_page=${linksPerPage}`);
            if (Array.isArray(res.data)) {
                setCoupons(res.data);
                setHasMorePages(res.data.length === linksPerPage);
            }
        } catch (error: any) {
            toast.error(`בעיה בעת משיכת הקופונים: ${error.message}`);
        }
    }, [apiService, linksPerPage]);

    useEffect(() => {
        fetchCoupons(currentPage);
    }, [currentPage, fetchCoupons]);

    const apiCall = useCallback(async (apiFunc: () => Promise<any>, successMessage: string, errorMessage: string) => {
        try {
            const res = await apiFunc();
            toast.success(successMessage);
            return res.data;
        } catch (error) {
            toast.error(errorMessage);
        }
    }, []);

    const handleDeleteConfirmation = useCallback(() => {
        if (selectedCoupon) {
            const couponId = selectedCoupon.id;
            apiCall(
                () => apiService.api.delete(`/coupon/${couponId}`),
                'הקופון נמחק בהצלחה',
                'בעיה בעת מחיקת הקופון'
            ).then(() => {
                setCoupons((prevCoupons) => prevCoupons.filter((pkg) => pkg.id !== couponId));
                setSelectedCoupon(null);
                setDeleteModalShow(false);
            });
        }
        setSelectedCoupon(null);
    }, [selectedCoupon, apiCall]);


    const handleAddCoupon = async (values: any) => {
        try {
            const res = await apiService.api.post('/coupon/', {
                ...values,
                expiresAt: values.expiresAt?.toISOString(), // Send ISO 8601 format
            });
            setCoupons((prevCoupons) => [res.data, ...prevCoupons]);
            toast.success('קופון נוסף בהצלחה');
            setShowAddModal(false);
        } catch (error) {
            toast.error('בעיה בעת הוספת קופון');
        }
    };

    const openDeleteModal = (pkg: Coupon) => {
        setSelectedCoupon(pkg);
        setDeleteModalShow(true);
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const validationSchema = yup.object().shape({
        discountType: yup
            .string()
            .required('סוג הנחה נדרש')
            .oneOf(['percentage', 'amount'], 'סוג הנחה לא חוקי'),
        discount: yup
            .number()
            .required('כמות הנחה נדרשת')
            .positive('כמות הנחה חייבת להיות חיובית')
            .typeError('כמות הנחה חייבת להיות מספר'),
        redemptionsLeft: yup
            .number()
            .required('כמות קופונים למימוש נרדשת')
            .min(1,  'כמות קופונים למימוש חייבת להיות גדולה או שווה ל1')
            .typeError('כמות קופונים למימוש חייבת להיות מספר'),
        belongsTo: yup
            .string()
            .email('האימייל אינו תקין')
            .when('type', {
                is: 'user',
                then: schema => schema.required('האימייל נדרש'),
                otherwise: schema => schema.notRequired(),
            }),
        expiresAt: yup
            .date()
            .required('תאריך תפוגה נדרש')
            .min(new Date(), 'תאריך תפוגה חייב להיות בעתיד'),
        type: yup
            .string()
            .oneOf(['unlimited', 'user', 'oneTimePerUser'], 'סוג הקופון חייב להיות אחד מהערכים המותרים')
            .required('סוג הקופון נדרש')
    });

    function getCouponType(type: "unlimited" | "user" | "oneTimePerUser") {
        switch (type) {
            case "unlimited":
                return 'לא מוגבל';
            case "user":
                return 'משתמש';
            case "oneTimePerUser":
                return 'מימוש אחד למשתמש';
        }
    }

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={5}>
                    <h1 className="mb-2">קופונים</h1>
                </Col>
                <Col md={5} className="d-flex justify-content-end align-items-center">
                    <Button onClick={() => setShowAddModal(true)}>הוספת קופון</Button>
                </Col>
                <Col md={10}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>קוד</th>
                            <th>סוג קופון</th>
                            <th>משתמש</th>
                            <th>מימושים שנותרו</th>
                            <th>סוג הנחה</th>
                            <th>כמות הנחה</th>
                            <th>תוקף</th>
                            <th>פעולות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {coupons.map((coupon) => (
                            <tr key={coupon.id}>
                                <td>{coupon.code}</td>
                                <td>{getCouponType(coupon.type)}</td>
                                <td>{coupon.user?.email}</td>
                                <td>{coupon.redemptionsLeft}</td>
                                <td>{coupon.discountType === 'percentage' ? 'אחוזים' : 'קבוע'}</td>
                                <td>{coupon.discount}</td>
                                <td>
                                    {new Date(coupon.expiresAt).toLocaleString('he-IL', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </td>
                                <td>
                                    <Button
                                        className="me-2"
                                        variant="danger"
                                        onClick={() => openDeleteModal(coupon)}
                                    >
                                        מחק
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <PaginationBar/>
                </Col>
            </Row>

            <ConfirmationModal
                show={deleteModalShow}
                onClose={() => setDeleteModalShow(false)}
                onConfirm={handleDeleteConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את הקופון?"
            />

            {/* Add Coupon Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>הוספת קופון</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        type: '',
                        discountType: '',
                        discount: '',
                        belongsTo: '',
                        redemptionsLeft: 1,
                        expiresAt: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleAddCoupon}
                >
                    {({values, errors, touched, handleChange, setFieldValue, handleSubmit}) => (
                        <>
                            <Modal.Body>
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>סוג הקופון</Form.Label>
                                        <Form.Select
                                            name="type"
                                            value={values.type}
                                            onChange={handleChange}
                                            isInvalid={touched.type && !!errors.type}
                                        >
                                            <option value="">בחר סוג קופון</option>
                                            <option value="unlimited">לא מוגבל</option>
                                            <option value="user">משתמש</option>
                                            <option value="oneTimePerUser">פעם אחת למשתמש</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.type}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>סוג הנחה</Form.Label>
                                        <Form.Select
                                            name="discountType"
                                            value={values.discountType}
                                            onChange={handleChange}
                                            isInvalid={touched.discountType && !!errors.discountType}
                                        >
                                            <option value="">בחר סוג הנחה</option>
                                            <option value="percentage">אחוזים</option>
                                            <option value="amount">קבוע</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.discountType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>כמות הנחה</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="discount"
                                            value={values.discount}
                                            onChange={handleChange}
                                            isInvalid={touched.discount && !!errors.discount}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.discount}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>כמות קופונים למימוש</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="redemptionsLeft"
                                            value={values.redemptionsLeft}
                                            onChange={handleChange}
                                            isInvalid={touched.redemptionsLeft && !!errors.redemptionsLeft}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.redemptionsLeft}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {values.type == 'user' && <Form.Group>
                                        <Form.Label>אימייל</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="belongsTo"
                                            value={values.belongsTo}
                                            onChange={handleChange}
                                            isInvalid={touched.belongsTo && !!errors.belongsTo}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.belongsTo}
                                        </Form.Control.Feedback>
                                    </Form.Group>}
                                    <Form.Group className="mt-4">
                                        <Form.Label>תאריך תפוגה</Form.Label>
                                        <DatePicker
                                            selected={values.expiresAt}
                                            onChange={(date) => setFieldValue('expiresAt', date)}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="Pp"
                                            placeholderText="בחר תאריך תפוגה"
                                            minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)} // Minimum date is 24 hours from now
                                            className={`form-control ${touched.expiresAt && errors.expiresAt ? 'is-invalid' : ''}`}
                                        />
                                        {touched.expiresAt && errors.expiresAt && (
                                            <div className="invalid-feedback d-block">{errors.expiresAt}</div>
                                        )}
                                    </Form.Group>

                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                                    ביטול
                                </Button>
                                <Button variant="primary" onClick={() => handleSubmit()}>
                                    שמירה
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default AdminCoupons;
