import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Order, {OrderTask, OrderTaskStatus} from "../../../models/Order";
import {useApiService} from "../../../context/ApiServiceContext";

interface EditStatusModalProps {
    show: boolean;
    order: Order | null;
    task: OrderTask | null;
    onClose: () => void;
    onStatusUpdate: (updatedOrder: Order, updatedTask: OrderTask) => void;
}

const statusToName = {
    [OrderTaskStatus.COMPLETED]: 'באוויר',
    [OrderTaskStatus.SENT]: 'נשלח לביצוע',
    [OrderTaskStatus.PENDING]: 'ממתין',
    [OrderTaskStatus.PENDING_DATA]: 'ממתין לחומרים',
    [OrderTaskStatus.IN_PROGRESS]: 'בעבודה',
}

const EditStatusModal: React.FC<EditStatusModalProps> = ({ show, order, task, onClose, onStatusUpdate }) => {
    const [status, setStatus] = useState<OrderTaskStatus>(task?.status || OrderTaskStatus.PENDING);
    const [articleUrl, setArticleUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const apiService = useApiService();

    const handleSave = async () => {
        if (!task || !order) return;

        if (status === OrderTaskStatus.COMPLETED && !articleUrl) {
            toast.error('יש להזין כתובת מאמר כאשר הסטטוס הוא הושלם');
            return;
        }

        try {
            setIsLoading(true);
            const payload = {
                status,
                articleUrl: articleUrl ? articleUrl : undefined
            };
            const response = await apiService.api.post(`/admin/order/${order.id}/task/${task.id}/status`, payload);

            onStatusUpdate(order, { ...task, ...response.data });
            toast.success('סטטוס המשימה עודכן בהצלחה');
            onClose();
        } catch (error) {
            console.error('Error updating task status:', error);
            toast.error('שגיאה בעדכון סטטוס המשימה');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>ערוך סטטוס משימה</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="statusSelect">
                        <Form.Label>סטטוס</Form.Label>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={(e) => setStatus(e.target.value as OrderTaskStatus)}
                        >
                            {Object.values(OrderTaskStatus).map((statusOption) => (
                                <option key={statusOption} value={statusOption}>
                                    {statusToName[statusOption]}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {status === OrderTaskStatus.COMPLETED && (
                        <Form.Group controlId="articleUrlInput" className="mt-3">
                            <Form.Label>כתובת מאמר</Form.Label>
                            <Form.Control
                                type="text"
                                value={articleUrl}
                                onChange={(e) => setArticleUrl(e.target.value)}
                                placeholder="הזן כתובת מאמר"
                            />
                        </Form.Group>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    ביטול
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                    שמור שינויים
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditStatusModal;
