import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Accordion, Card, Form, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {fetchOrderById, selectOrderById, updateOrderTask} from '../../utils/ordersSlice';
import {EditableOrder, OrderCategory, OrderTask, OrderTaskBody, OrderTaskStatus} from "../../models/Order";
import {AppDispatch, RootState} from "../../utils/store"; // Assuming you have an updateOrder action
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, CircularProgress} from "@mui/material";
import {styled} from "@mui/material/styles";
import arrow from '../../assets/images/arrow-orders.svg';
import he from "date-fns/locale/he";
import {formatISO} from 'date-fns';
import {useApiService} from "../../context/ApiServiceContext";
import Link, {SiteType} from "../../models/Link";
import {makeStyles} from "@material-ui/core";
import CustomSelect from "./views/CustomSelect";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import {trimToHostname} from "../../utils/utils";
import Spinner from "../../views/Spinner";
import AnalyticsService, {AnalyticsEventName} from "../../services/AnalyticsService";

registerLocale("he", he);

const useStyles = makeStyles((theme) => ({
    modal: {
        // transform: "translate(-50%, -50%)",
        "& .modal-body": {
            maxHeight: '500px',
            overflowY: 'auto'
        },
        "@media (max-height:830px)": {
            marginTop: "50px",
            "& .modal-body": {
                maxHeight: '400px',
            },
        },
        "@media (max-height:630px)": {
            marginTop: "50px",
            "& .modal-body": {
                maxHeight: '300px',
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "48px",
            "& .modal-body": {
                maxHeight: 'unset'
            },
            "& .modal-footer": {
                marginBottom: "48px"
            }
        },
    }
}))

interface Props {
    handleClose: () => void;
    open: boolean;
    orderId: string | null;
    taskId?: string | null;
}

const OrderDetailsModal: React.FC<Props> = function ({handleClose, open, orderId, taskId}) {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const apiService = useApiService();
    const analyticsService = AnalyticsService.getInstance();
    const dispatch: AppDispatch = useDispatch();
    const orderFromStore = useSelector((state: RootState) => selectOrderById(state, orderId ?? ""));
    const [editableOrder, setEditableOrder] = useState<EditableOrder | undefined>(orderFromStore ? {
        ...orderFromStore,
        tasks: orderFromStore.tasks.map(x => ({
            ...x,
            linkingSiteId: x.linkingSite?.id,
            linkingSiteHostname: x.linkingSite?.hostname
        })) ?? []
    } : undefined);
    const [validationStates, setValidationStates] = useState<{ [key: string]: boolean }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSendingOrder, setSendingOrder] = useState<boolean>(false);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const [categories, setCategories] = useState<OrderCategory[]>([]);

    // State to track opened accordion items
    const [openedItems, setOpenedItems] = useState(new Set());
    const [activeKey, setActiveKey] = useState<string | null>(null);

    const isFirstLoad = useRef(true);

    // Function to handle accordion item toggle
    const handleAccordionToggle = (eventKey: AccordionEventKey) => {
        setActiveKey(eventKey as string)
        setOpenedItems(prevOpenedItems => new Set(prevOpenedItems.add(eventKey)));
    };

    useEffect(() => {
        if (!orderFromStore && orderId) {
            setIsLoading(true)
            dispatch(fetchOrderById(orderId))
                .catch(() => {
                    setEditableOrder(undefined)
                    setIsLoading(false)
                })
        }
    }, [orderId, orderFromStore, dispatch]);

    useEffect(() => {
        if (orderFromStore) {
            let tasks = orderFromStore.tasks.map(x => ({
                ...x,
                linkingSiteId: x.linkingSite?.id,
                linkingSiteHostname: x.linkingSite?.hostname,
                scheduledTime: formatISO(
                    x['scheduledTime'] ?
                        new Date(x['scheduledTime']) :
                        getMinDate(), {representation: 'complete'})
            }))

            if (taskId) {
                tasks = tasks.filter(task => task.id == taskId)
            }

            const order = {
                ...orderFromStore,
                tasks
            }
            if (isFirstLoad.current) {
                openFirstPendingAccordionItem(order)
                isFirstLoad.current = false
            }

            setUniqueCategories(order)
            setEditableOrder(order);
            setIsLoading(false)
        }
    }, [orderFromStore]);

    function setUniqueCategories(order: EditableOrder) {
        const uniqueCategoriesMap = new Map();

        order.tasks.forEach(x => {
            if (x.category && !uniqueCategoriesMap.has(x.category.id)) {
                uniqueCategoriesMap.set(x.category.id, x.category);
            }

            if (x.subCategories) {
                x.subCategories.forEach(subCategory => {
                    if (!uniqueCategoriesMap.has(subCategory.id)) {
                        uniqueCategoriesMap.set(subCategory.id, subCategory);
                    }
                });
            }
        });

        setCategories(Array.from(uniqueCategoriesMap.values()))
    }

    function openFirstPendingAccordionItem(order: EditableOrder) {
        let openIndex = order.tasks.findIndex(x => [OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(x.status))
        if (openIndex === -1) {
            openIndex = 0
        }
        const openIndexStr = String(openIndex)
        // lets load the data for the open accordion item
        handleAccordionToggle(openIndexStr)
        // open the accordion
        setActiveKey(openIndexStr)
    }

    /** validation start */
    const validateWebsite = (url: string, index: number, field: keyof OrderTaskBody): boolean => {
        // Regular expression for validating a URL
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[:;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        const isValid = pattern.test(url)
        setValidationStates({
            ...validationStates,
            [`${index}-${field}`]: isValid,
        });
        return isValid;
    };

    const validateDate = (date: Date | null, index: number): boolean => {
        // Date validation logic (e.g., not null, within a range, etc.)
        let isValid = date !== null; // Simple non-null validation
        setValidationStates({
            ...validationStates,
            [`${index}-scheduledTime`]: isValid,
        });
        return isValid
    };

    const validateInput = (value: string, index: number, field: keyof OrderTaskBody): boolean => {
        // Update validation state
        const isValid = (value?.trim()?.length ?? 0) > 0;
        setValidationStates({
            ...validationStates,
            [`${index}-${field}`]: isValid,
        });
        return isValid
    };

    /** validation end */

    enum ValidatorType {
        WEBSITE,
        TEXT
    }

    type FieldType = {
        key: keyof OrderTaskBody,
        type: ValidatorType
    }

    const FIELD: {
        [key: string]: FieldType
    } = {
        LINKING_SITE: {
            key: "linkingSiteId",
            type: ValidatorType.TEXT
        },
        ANCHOR: {
            key: "anchor",
            type: ValidatorType.TEXT
        },
        ANCHOR_LINK: {
            key: "anchorLink",
            type: ValidatorType.WEBSITE
        },
        SECOND_ANCHOR: {
            key: "secondAnchor",
            type: ValidatorType.TEXT
        },
        SECOND_ANCHOR_LINK: {
            key: "secondAnchorLink",
            type: ValidatorType.WEBSITE
        },
        SCHEDULED_TIME: {
            key: "scheduledTime",
            type: ValidatorType.TEXT
        },
    }

    const requiredFields = [
        FIELD.LINKING_SITE,
        FIELD.ANCHOR,
        FIELD.ANCHOR_LINK,
        FIELD.SCHEDULED_TIME
    ]

    const handleInputChange = (index: number, field: FieldType, value: string) => {
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {...updatedTasks[index], [field.key]: value};
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }

        validate(index, field, value);
    };

    const handleLinkingSiteChange = (index: number, value: Link) => {
        const field = FIELD.LINKING_SITE.key
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {
            ...updatedTasks[index],
            linkingSiteHostname: value.website,
            linkingSiteId: value.id
        };

        console.log("linking site", {
            linkingSiteHostname: value.website,
            linkingSiteId: value.id
        })

        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }

        setValidationStates({
            ...validationStates,
            [`${index}-${field}`]: value.website.length > 0,
        });
    };

    const handleRadioChangeForTask = (index: number, isPrecise: boolean) => {
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {...updatedTasks[index], preciseAnchor: isPrecise};
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }
    };

    const validate = (index: number, field: FieldType, value: string) => {
        if (field.type === ValidatorType.WEBSITE) {
            return validateWebsite(value, index, field.key)
        } else {
            return validateInput(value, index, field.key)
        }
    }

    const handleDateChange = (index: number, field: FieldType, date: Date | null) => {
        const updatedTasks = [...(editableOrder?.tasks ?? [])];
        updatedTasks[index] = {
            ...updatedTasks[index],
            [field.key]: formatISO(date ?? getMinDate(), {representation: 'complete'})
        }; // Store date as ISO string
        if (editableOrder) {
            setEditableOrder({...editableOrder, tasks: updatedTasks});
        }
    };

    const handleFileChange = (index: number, taskId: string, file: File | null) => {
        if (file) {
            setFileUploading(true)

            const formData = new FormData();
            formData.append('file', file);

            apiService.api.post<OrderTask>(`/order/${orderId}/task/${taskId}/file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(() => {
                    setValidationStates({
                        ...validationStates,
                        [`${index}-file`]: true
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setValidationStates({
                        ...validationStates,
                        [`${index}-file`]: false
                    });
                })
                .finally(() => {
                    setFileUploading(false)
                });
        }
    };

    const openPresignedUrl = (taskId: string) => {
        apiService.api.get(`/order/${orderId}/task/${taskId}/file`)
            .then((response) => {
                window.open(response.data.url, '_blank')
            })
            .catch((error) => {
                toast.error("נתקלנו בבעיה בפתיחת הקישור")
            })
    };

    const isFormValid = (index: number): boolean => {
        let isValid = true;

        const task = editableOrder?.tasks[index];

        const vStates: any = {}
        requiredFields.forEach(field => {
            const fieldKey = `${index}-${field.key}`;
            if (task && field.key in task && validate(index, field, task[field.key] as string)) {
                vStates[fieldKey] = true
            }

            if (validationStates[fieldKey] !== true && !vStates[fieldKey] &&
                !(editableOrder?.itemType == 'link' && field === FIELD.LINKING_SITE)) {
                // If the field is missing or not valid, set it to false

                vStates[fieldKey] = false;
                isValid = false;
            }
        });

        setValidationStates({
            ...validationStates,
            ...vStates
        })

        if (task?.linkingSite?.type === SiteType.PREMIUM && task?.usersContent && !task?.documentUrl &&
            !validationStates[`${index}-file`]) {
            isValid = false;
        }

        return isValid;
    };

    const handleInitTask = async (index: number) => {
        try {

            const task = editableOrder?.tasks?.[index]
            if (task && isFormValid(index)) {
                setSendingOrder(true)

                const body: OrderTaskBody = {
                    anchor: task.anchor,
                    anchorLink: task.anchorLink,
                    linkingSiteId: task.linkingSiteId,
                    scheduledTime: task.scheduledTime,
                    preciseAnchor: task.preciseAnchor ?? false
                }

                if (orderFromStore?.allowSecondAnchor) {
                    if (task.secondAnchor) {
                        body['secondAnchor'] = task.secondAnchor
                    }

                    if (task.secondAnchorLink) {
                        body['secondAnchorLink'] = task.secondAnchorLink
                    }
                }

                const newTask = await initTask(editableOrder.id, task.id, body);

                dispatch(updateOrderTask([orderFromStore!, newTask.data]))

                analyticsService.sendCustomEvent(AnalyticsEventName.LinkInitiated, {
                    packageSize: editableOrder.tasks.length,
                    price: editableOrder.totalPrice,
                    linkType: task.siteType,
                    fulfilledLinks: editableOrder?.tasks?.filter(t => t.status != OrderTaskStatus.PENDING)?.length + 1,
                })

                toast.success('ההזמנה נשלחה בהצלחה');
                setActiveKey(null)
            }
        } catch (e) {
            toast.error("נתקלנו בבעיה בעת עדכון המשימה, נסו מאוחר יותר")
        } finally {
            setSendingOrder(false)
        }
    };

    const handleUpdateTask = async (index: number) => {
        try {
            const task = editableOrder?.tasks?.[index]
            if (task && isFormValid(index)) {
                setSendingOrder(true)
                const body: OrderTaskBody = {
                    anchor: task.anchor,
                    anchorLink: task.anchorLink,
                    linkingSiteId: task.linkingSiteId,
                    scheduledTime: task.scheduledTime,
                    preciseAnchor: task.preciseAnchor ?? false
                }

                if (orderFromStore?.allowSecondAnchor) {
                    if (task.secondAnchor) {
                        body['secondAnchor'] = task.secondAnchor
                    }

                    if (task.secondAnchorLink) {
                        body['secondAnchorLink'] = task.secondAnchorLink
                    }
                }


                const newTask = await updateTask(editableOrder.id, task.id, body);
                dispatch(updateOrderTask([orderFromStore!, newTask.data]))

                toast.success('ההזמנה עודכנה בהצלחה');
                setActiveKey(null)
            }
        } catch (e) {
            toast.error("נתקלנו בבעיה בעת עדכון המשימה, נסו מאוחר יותר")
        } finally {
            setSendingOrder(false)
        }
    };

    const handleCloseAndCancel = () => {
        console.log('Closing modal'); // For debugging

        setEditableOrder(orderFromStore ? {
            ...orderFromStore,
            tasks: orderFromStore.tasks.map(x => ({
                ...x,
                linkingSiteId: x.linkingSite?.id,
                linkingSiteHostname: x.linkingSite?.hostname
            })) ?? []
        } : undefined);
        handleClose();

        const currentPath = location.pathname;
        navigate(`${currentPath}`, {replace: true});
    };

    const initTask = (orderId: string, taskId: string, payload: OrderTaskBody) => {
        return apiService.api.post<OrderTask>(`/order/${orderId}/task/${taskId}`, payload);
    };

    const updateTask = (orderId: string, taskId: string, payload: OrderTaskBody) => {
        return apiService.api.put<OrderTask>(`/order/${orderId}/task/${taskId}`, payload);
    };

    if (isLoading) {
        return (
            <Modal show={open} onHide={handleCloseAndCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>טוען את ההזמנה</Modal.Title>
                    {isLoading && <Spinner size={35}/>}
                </Modal.Header>
                <Modal.Body>
                    <div>זה יקח ממש עוד רגע</div>
                </Modal.Body>
            </Modal>
        );
    } else if (!editableOrder) {
        return (
            <Modal show={open} onHide={handleCloseAndCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>יש לנו בעיה</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>לא נמצאו פרטי הזמנה.</div>
                </Modal.Body>
            </Modal>
        );
    }


    const StyledAccordionHeader = styled(Accordion.Header)(({theme}) => ({
        "& .accordion-button": {
            backgroundColor: "#ffffff",
            color: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px 0",
            border: "none",
            fontWeight: 600,
            fontSize: "26px",
            width: "94%",
            margin: "0 3% 0 0",
            boxShadow: "inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)",
        },
        "& .accordion-button::after": {
            backgroundImage: `url(${arrow})`
        },
        "& .accordion-button:focus": {
            border: "none",
            outline: "none",
        }
    }));

    function getMinDate() {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 7)
        return minDate
    }

    return (
        <Modal show={open} onHide={handleCloseAndCancel} centered className={classes.modal} fullscreen="sm-down">
            <Modal.Header closeButton>
                <Modal.Title>הזמנה</Modal.Title> {/* מספר {editableOrder.id}*/}
            </Modal.Header>
            <Modal.Body>
                <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
                    {editableOrder.tasks.map((task, index) => (
                        <Card key={index} className="mb-3">
                            <Accordion.Item eventKey={String(index)}>
                                {!taskId ? <StyledAccordionHeader>קישור {index + 1}</StyledAccordionHeader> :
                                    <StyledAccordionHeader>קישור {orderFromStore!.tasks.findIndex(task => task.id == taskId) + 1}</StyledAccordionHeader>}
                                <Accordion.Body>

                                    <Card.Body>
                                        <Form>

                                            <Form.Group controlId={`anchorLink-${index}`}>
                                                <Form.Label>קישור לאנקור (url שלך)</Form.Label>
                                                <Form.Control
                                                    dir="ltr"
                                                    type="text"
                                                    value={task.anchorLink || ''}
                                                    onChange={(e) => handleInputChange(index, FIELD.ANCHOR_LINK, e.target.value)}
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                    isInvalid={validationStates[`${index}-${FIELD.ANCHOR_LINK.key}`] != undefined &&
                                                        !validationStates[`${index}-${FIELD.ANCHOR_LINK.key}`]}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    אנא הכנס כתובת אתר תקינה.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId={`linkingSite-${index}`}>
                                                {/* only load the dropdown when the accordion is active */}
                                                {openedItems.has(String(index)) && (editableOrder?.itemType === 'package' ? (
                                                    <CustomSelect
                                                        value={task.linkingSiteHostname || ''}
                                                        onChange={(value) => handleLinkingSiteChange(index, value)}
                                                        disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                        isInvalid={validationStates[`${index}-${FIELD.LINKING_SITE.key}`] != undefined &&
                                                            !validationStates[`${index}-${FIELD.LINKING_SITE.key}`]}
                                                        siteType={editableOrder.tasks[0].siteType}
                                                        anchor={editableOrder.tasks[index].anchorLink || ''}
                                                        required
                                                    />
                                                ) : (
                                                    <>
                                                        <Form.Label>אתר מקשר (האתר בו יופיע המאמר עם
                                                            הקישור)</Form.Label>
                                                        <Form.Control
                                                            dir="ltr"
                                                            type="text"
                                                            value={trimToHostname(task.linkingSiteHostname) || ''}
                                                            // onChange={(e) => handleLinkingSiteChange(index, e.target.value)}
                                                            disabled={editableOrder?.itemType == 'link' ||
                                                                ![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                            isInvalid={validationStates[`${index}-${FIELD.LINKING_SITE.key}`] != undefined &&
                                                                !validationStates[`${index}-${FIELD.LINKING_SITE.key}`]}
                                                            required
                                                        />
                                                    </>
                                                ))}
                                                <Form.Control.Feedback type="invalid">
                                                    יש לבחור אתר מהרשימה.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mt-2" controlId={`anchor-${index}`}>
                                                <Form.Label>אנקור (הטקטס שעליו יולבש הקישור)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={task.anchor || ''}
                                                    onChange={(e) => handleInputChange(index, FIELD.ANCHOR, e.target.value)}
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                    isInvalid={validationStates[`${index}-${FIELD.ANCHOR.key}`] != undefined &&
                                                        !validationStates[`${index}-${FIELD.ANCHOR.key}`]}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    חובה למלא את השדה
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {orderFromStore?.allowSecondAnchor &&
                                                <>
                                                    <Form.Group className="mt-2"
                                                                controlId={`secondAnchorLink-${index}`}>
                                                        <Form.Label>קישור לאנקור נוסף</Form.Label>
                                                        <Form.Control
                                                            dir="ltr"
                                                            type="text"
                                                            value={task.secondAnchorLink || ''}
                                                            onChange={(e) => handleInputChange(index, FIELD.SECOND_ANCHOR_LINK, e.target.value)}
                                                            disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                            isInvalid={validationStates[`${index}-${FIELD.SECOND_ANCHOR_LINK.key}`] != undefined &&
                                                                !validationStates[`${index}-${FIELD.SECOND_ANCHOR_LINK.key}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            אנא הכנס כתובת אתר תקינה.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="mt-2" controlId={`secondAnchor-${index}`}>
                                                        <Form.Label>אנקור נוסף</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={task.secondAnchor || ''}
                                                            onChange={(e) => handleInputChange(index, FIELD.SECOND_ANCHOR, e.target.value)}
                                                            disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                            isInvalid={validationStates[`${index}-${FIELD.SECOND_ANCHOR.key}`] != undefined &&
                                                                !validationStates[`${index}-${FIELD.SECOND_ANCHOR.key}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            חובה למלא את השדה
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </>
                                            }

                                            <Form.Group className="mt-2">
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={<Tooltip id="tooltip-right">תתאימו לי את האנקור בהקשר
                                                        הנכון</Tooltip>}
                                                >
                                                    <Form.Check
                                                        inline
                                                        label="תתאימו לי"
                                                        name="options"
                                                        type="radio"
                                                        checked={task.preciseAnchor == false}
                                                        onChange={() => handleRadioChangeForTask(index, false)}
                                                    />
                                                </OverlayTrigger>
                                                <Form.Check
                                                    inline
                                                    label="אנקור מדויק"
                                                    name="options"
                                                    type="radio"
                                                    checked={task.preciseAnchor == true}
                                                    onChange={() => handleRadioChangeForTask(index, true)}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mt-2" controlId={`orderTime-${index}`}>
                                                <Form.Label>תאריך הזמנה</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={task.orderTime && new Date(task.orderTime).toLocaleDateString('he-IL') || '-'}
                                                    disabled // assuming creation date should not be editable
                                                />
                                            </Form.Group>

                                            <Form.Group controlId={`scheduledTime-${index}`} className="mt-3">
                                                <Form.Label className="me-2">תאריך ביצוע</Form.Label>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={task.scheduledTime ? new Date(task.scheduledTime) : null}
                                                    onChange={(date) => {
                                                        handleDateChange(index, FIELD.SCHEDULED_TIME, date)
                                                        validateDate(date, index);
                                                    }}
                                                    locale="he"
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={getMinDate()}
                                                    required
                                                    disabled={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    אנא הכנס תאריך תקין.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            {task.linkingSite?.type === SiteType.PREMIUM && task.usersContent && (
                                                ([OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)) ?
                                                    <Form.Group className="mt-3 mb-3" controlId={`file-${index}`}>
                                                        <Form.Label>
                                                            <div>
                                                                <span>קובץ</span>
                                                                <CircularProgress className="ms-3" color="secondary"
                                                                                  size={13}
                                                                                  hidden={!isFileUploading}/>
                                                                <span className="ms-2"
                                                                      hidden={!isFileUploading}>מעלה את הקובץ</span>
                                                            </div>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                handleFileChange(index, task.id, e.target.files ? e.target.files[0] : null)}
                                                            hidden={![OrderTaskStatus.PENDING, OrderTaskStatus.PENDING_DATA].includes(task?.status)}
                                                        />
                                                    </Form.Group>
                                                    :
                                                    <Button variant="text"
                                                            className="text blue mb-2 w-auto align-items-start fs-5 p-0 mt-2"
                                                            onClick={() => openPresignedUrl(task.id)}>
                                                        פתח קובץ
                                                    </Button>
                                            )}

                                            {task.articleUrl && (
                                                <div className="d-flex align-items-center">
                                                    <Button
                                                        className="simple-button medium blue mt-3 mx-auto"
                                                        onClick={() => window.open(task.articleUrl, '_blank')}>
                                                        קישור למאמר
                                                    </Button>
                                                </div>
                                            )}

                                            <div className="d-flex align-items-center mt-3">
                                                {task.status == OrderTaskStatus.PENDING && (
                                                    <Button className="simple-button medium blue"
                                                            disabled={isSendingOrder || isFileUploading}
                                                            onClick={() => handleInitTask(index)}>
                                                        שלח הזמנה
                                                    </Button>)}

                                                {task.status == OrderTaskStatus.PENDING_DATA && (
                                                    <Button className="simple-button medium blue"
                                                            disabled={isSendingOrder || isFileUploading}
                                                            onClick={() => handleUpdateTask(index)}>
                                                        עדכן הזמנה
                                                    </Button>)}
                                                <Spinner className="ms-3" size={30} hidden={
                                                    ![OrderTaskStatus.PENDING,
                                                        OrderTaskStatus.PENDING_DATA].includes(task.status) ||
                                                    !isSendingOrder
                                                }/>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Accordion.Body>

                            </Accordion.Item>
                        </Card>
                    ))}
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outlined" color="secondary" onClick={handleCloseAndCancel}>
                    סגור
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderDetailsModal;
