import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    numberContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        fontFamily: '"Assistant", sans-serif',
    },
    number: {
        fontSize: '70px', // Default size
        fontWeight: 900,
        letterSpacing: -3,
        lineHeight: 1,
        [theme.breakpoints.up('lg')]: {
            fontSize: '80px', // Larger screen size (large)
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '60px', // Medium screen size (medium)
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '40px', // Smaller screen size (small)
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '32px', // Smaller screen size (small)
        },
    },
    caption: {
        textAlign: 'center',
        fontSize: '18px', // Default size
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px', // Smaller screen size (small)
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '16px', // Smaller screen size (small)
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '17px', // Medium screen size (medium)
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '19px', // Larger screen size (large)
        },
    },
    verticalLine: {
        borderLeft: '2px solid black',
        height: '100px',
        [theme.breakpoints.down('sm')]: {
            height: '80px',
        },
    },
}));

interface AnimatedNumberProps {
    target: number;
    duration: number;
    prefix?: string;
    suffix?: string;
}

// Reusable animated number component
const AnimatedNumber: React.FC<AnimatedNumberProps> = ({target, duration, prefix, suffix}) => {
    const [number, setNumber] = useState(0);

    useEffect(() => {
        const frameDuration = 1000 / 60;
        const totalFrames = Math.round(duration / frameDuration);
        let frame = 0;

        const counter = setInterval(() => {
            frame++;
            const progress = frame / totalFrames;
            const currentNumber = Math.round(target * progress);

            if (frame === totalFrames) {
                clearInterval(counter);
                setNumber(target);
            } else {
                setNumber(currentNumber);
            }
        }, frameDuration);

        return () => clearInterval(counter);
    }, [target, duration]);

    return <span dir="ltr">{prefix}{number}{suffix}</span>;
};

// The component for the whole number set with captions
const NumbersSet: React.FC = () => {
    const classes = useStyles({});

    return (
        <div className={classes.numberContainer}>
            <div>
                <div className={classes.number}>
                    <AnimatedNumber target={400} duration={2000} prefix="+"/>
                </div>
                <div className={classes.caption}>אתרים זמינים</div>
            </div>
            <div className={`${classes.verticalLine} me-4 ms-4`}></div>
            <div>
                <div className={classes.number}>
                    <AnimatedNumber target={100} duration={2000} suffix="%"/>
                </div>
                <div className={classes.caption}>תוכן אורגני</div>
            </div>
            <div className={`${classes.verticalLine} me-4 ms-4`}></div>
            <div>
                <div className={classes.number}>
                    <AnimatedNumber target={150} duration={2000} prefix="+" suffix="%"/>
                </div>
                <div className={classes.caption}>כניסות לאתר</div>
            </div>
        </div>
    );
};

export default NumbersSet;
