import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useApiService} from "../../context/ApiServiceContext";
import User from "../../models/User";
import {useFormik} from "formik";
import * as yup from 'yup';
import Package from "../../models/Package";
import ConfirmationModal from "../../utils/ConfirmationModal";
import AttachPackageToUserModal from "./user/AttachPackageToUser";
import AttachLinkToUserModal from "./user/AttachLinkToUser";

interface UserRequestBody {
    name: string;
    phone: string | null;
    email: string;
    field: string | null;
    clientType: string | null;
    companySize: number | null;
    websites: string[];
    admin: boolean;
}

const AdminUsers: React.FC = () => {
    const apiService = useApiService();
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
    const [showPackagesModal, setShowPackagesModal] = useState(false);
    const [showLinksModal, setShowLinksModal] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    // Pagination and filter state
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [emailFilter, setEmailFilter] = useState('');
    const linksPerPage = 20;
    // Fetch users with pagination and optional email filter
    useEffect(() => {
        const fetchUsers = async () => {
            // Update the endpoint URL with pagination and email filtering
            let url = `/user/list?page=${currentPage}&per_page=${linksPerPage}`;
            if (emailFilter && emailFilter != '') {
                url += `&email=${emailFilter}`
            }
            try {
                const res = await apiService.api.get(url);
                setUsers(res.data); // Assuming the response structure includes an array of users
                setHasMorePages(res.data.length === linksPerPage); // Assuming the response includes a flag for more pages
            } catch (error) {
                toast.error('Error fetching users');
                console.error(error);
            }
        };

        fetchUsers();
    }, [apiService, currentPage, emailFilter]);

    // Handle email filter change
    const handleEmailFilterChange = (value?: string | undefined) => {
        setEmailFilter(value ?? '');
        setCurrentPage(1); // Reset to the first page when filter changes
    };

    const handleDeleteConfirmation = useCallback(() => {
        if (selectedUser) {
            const userId = selectedUser.id
            apiService.api
                .delete(`/user/${userId}`)
                .then(() => {
                    setUsers(users.filter((user) => user.id !== userId));
                    toast.success('המשתמש נמחק בהצלחה');
                })
                .catch(() => {
                    toast.error('תקלה בעת מחיקת משתמש');
                })
                .finally(() => {
                    setDeleteModalShow(false)
                    setSelectedUser(null)
                });
        }
    }, [selectedUser]);


    const handleUpdate = (values: any) => {
        if (selectedUser) {
            const updatedUser = {
                ...values,
            };

            delete updatedUser.email;
            updatedUser.websites = updatedUser.websites.filter((x: string) => x !== "");

            apiService.api
                .put(`/user/${selectedUser.id}`, updatedUser)
                .then((response) => {
                    setUsers((prevUsers) =>
                        prevUsers.map((user) =>
                            user.id === response.data.id ? response.data : user
                        )
                    );
                    toast.success('המשתמש עודכן בהצלחה');
                })
                .catch(() => {
                    toast.error('תקלה בעת עדכון משתמש');
                });

            setShowModal(false);
        }
    };

    const openModal = (user: User | null) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const openDeleteModal = (user: User) => {
        setSelectedUser(user);
        setDeleteModalShow(true);
    };

    const openUserDetailsModal = (user: User) => {
        setSelectedUser(user);
        setShowUserDetailsModal(true);
    };

    const userSchema = yup.object().shape({
        name: yup.string().required('שם משתמש נדרש'),
        phone: yup.string().nullable().matches(/^[0-9]+$/, 'מספר טלפון חייב להיות מספרי'),
        email: yup.string().required('דוא"ל נדרש').email('כתובת דוא"ל לא תקינה'),
        field: yup.string().nullable(),
        clientType: yup.string().nullable(),
        companySize: yup.number().nullable().min(0, 'גודל חברה לא יכול להיות שלילי'),
        websites: yup.array().of(yup.string().url('כתובת אתר לא חוקית')),
        admin: yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            name: selectedUser?.name ?? '',
            phone: selectedUser?.phone ?? null,
            email: selectedUser?.email ?? '',
            field: selectedUser?.field ?? null,
            clientType: selectedUser?.clientType ?? null,
            companySize: selectedUser?.companySize ?? null,
            websites: selectedUser?.websites ?? [],
            admin: selectedUser?.admin ?? false,
        },
        validationSchema: userSchema,
        onSubmit: (values) => {
            console.log(values)
            handleUpdate(values);
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const handleWebsitesChange = (websites: string) => {
        // Split the string by commas and trim spaces
        const websitesArray = websites.split(',').map(site => site.trim());
        formik.setFieldValue('websites', websitesArray);
    };

    return (
        <div className="row justify-content-center py-md-5">
            <Row className="justify-content-center py-md-5 col-md-10">
                <Col md={4}>
                    <h1 className="mb-2">משתמשים</h1>
                </Col>
                <Col md={8} className="d-flex justify-content-end align-items-center">
                    <Form onSubmit={e => handleEmailFilterChange()} className="me-4 w-50">
                        <InputGroup>
                            <Form.Control
                                placeholder="חפש לפי אימייל"
                                onChange={event => handleEmailFilterChange(event.target.value)}
                                value={emailFilter}
                            />
                            <Button variant="outline-secondary" onClick={e => handleEmailFilterChange()}>
                                חפש
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md={12}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>מזהה</th>
                            <th>שם</th>
                            <th>טלפון</th>
                            <th>אימייל</th>
                            <th>תאריך יצירה</th>
                            <th>פעולות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user) => (
                            <tr key={user.id} onClick={() => openUserDetailsModal(user)}>
                                <td>{user.id}</td>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>
                                    {new Date(user.createdAt).toLocaleString('he-IL', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </td>
                                <td>
                                    <Button
                                        className="me-2"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openModal(user);
                                        }}
                                    >
                                        עריכה
                                    </Button>
                                    <Button
                                        variant="danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openDeleteModal(user);
                                        }}
                                    >
                                        מחיקה
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <PaginationBar/>
                </Col>

                <Modal show={showModal} onHide={() => {
                    setShowModal(false)
                    formik.resetForm()
                }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            עריכת משתמש
                        </Modal.Title>
                    </Modal.Header>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">שם</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.name && !!formik.errors.name}
                                    placeholder="הכנס שם"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="phone">טלפון</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.phone && !!formik.errors.phone}
                                    placeholder="הכנס טלפון"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.phone}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">אימייל</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.email && !!formik.errors.email}
                                    placeholder="הכנס אימייל"
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="field">תחום</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="field"
                                    name="field"
                                    value={formik.values.field || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס תחום"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="clientType">סוג לקוח</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="clientType"
                                    name="clientType"
                                    value={formik.values.clientType || ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס סוג לקוח"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="companySize">גודל חברה</Form.Label>
                                <Form.Control
                                    type="number"
                                    id="companySize"
                                    name="companySize"
                                    value={formik.values.companySize || 0}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס גודל חברה"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="websites">אתרים</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="websites"
                                    name="websites"
                                    value={formik.values.websites.join(', ')}
                                    onChange={(e) => handleWebsitesChange(e.target.value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="הכנס אתרים (מופרדים בפסיק)"
                                    isInvalid={formik.touched.websites && !!formik.errors.websites}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.websites}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 form-check">
                                <Form.Check
                                    type="checkbox"
                                    id="admin"
                                    name="admin"
                                    label="מנהל"
                                    checked={formik.values.admin}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                סגור
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                            >
                                שמור
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                <Modal
                    show={showUserDetailsModal}
                    onHide={() => setShowUserDetailsModal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>פרטי משתמש - {selectedUser?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>שם: {selectedUser?.name}</p>
                        <p>טלפון: {selectedUser?.phone || 'לא צוין'}</p>
                        <p>אימייל: {selectedUser?.email}</p>
                        <p>תחום: {selectedUser?.field || 'לא צוין'}</p>
                        <p>סוג לקוח: {selectedUser?.clientType || 'לא צוין'}</p>
                        <p>גודל חברה: {selectedUser?.companySize || 'לא צוין'}</p>
                        <p>
                            אתרים: {selectedUser?.websites.join(', ') || 'לא צוינו אתרים'}
                        </p>
                        <p>מנהל: {selectedUser?.admin ? 'כן' : 'לא'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='outline-primary'
                            onClick={() => {
                                setShowUserDetailsModal(false)
                                setShowLinksModal(true)
                            }}
                        >
                            הענקת קישור
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setShowUserDetailsModal(false)
                                setShowPackagesModal(true)
                            }}
                        >
                            הענקת חבילה
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setShowUserDetailsModal(false)}
                        >
                            סגור
                        </Button>
                    </Modal.Footer>
                </Modal>
                <AttachPackageToUserModal
                    userId={selectedUser ? selectedUser.id : ''}
                    show={showPackagesModal}
                    onHide={() => setShowPackagesModal(false)}
                />
                <AttachLinkToUserModal
                    userId={selectedUser ? selectedUser.id : ''}
                    show={showLinksModal}
                    onHide={() => setShowLinksModal(false)}
                />
            </Row>

            <ConfirmationModal
                show={deleteModalShow}
                onClose={() => setDeleteModalShow(false)}
                onConfirm={handleDeleteConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את המשתמש?"
            />
        </div>
    );
};

export default AdminUsers;
