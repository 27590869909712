import React from 'react';
import {makeStyles} from '@material-ui/core';
import whatsappIcon from "../../assets/images/logos_whatsapp-icon.png";
import BottomBorder from "../BottomBorder";
import {Theme} from "@mui/material";
import AnalyticsService from "../../services/AnalyticsService";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: 'linear-gradient(91deg, #221CB6 27.81%, #7F7AEB 74.43%)', // This color should be the blue from your image
        borderRadius: '20px',
        padding: '20px',
        color: 'white',
        maxWidth: '80%',
        margin: 'auto',
        position: 'relative', // For absolute positioning of the button
        textAlign: 'center' as const, // TypeScript needs 'as const' for literal types

        [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
        }
    },
    title: {
        fontSize: '24px',
    },
    description: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    button: {
        backgroundColor: 'white', // WhatsApp green color
        color: '#221CB6',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 20px',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        margin: '0 auto',
        textDecoration: "none"
    },
    icon: {
        marginLeft: '10px',
    },
    // Add styles for the WhatsApp icon as needed
}));

const ContactViaWhatsapp: React.FC = () => {
    const classes = useStyles();

    const analyticsService = AnalyticsService.getInstance();

    const handleWhatsAppClick = () => {
        analyticsService.sendPageViewEvent({
            pagePath: 'whatsapp',
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.title}>צור קשר</div>
            <div className="ms-auto me-auto d-inline-block position-relative" style={{top: "-10px"}}>
                <BottomBorder width={50} color="#ffffff"></BottomBorder>
            </div>
            <div className={classes.description}>
                <span>חושבים שיש לנו את הפתרון המתאים בשבילכם? רוצים לשמוע עוד פרטים על החבילות שלנו?</span>
                <br/>
                <span>אנחנו במרחק כפתור אחד כאן למטה, נסו אותנו</span>
            </div>
            <a href="https://wa.me/972542622996" className={classes.button} target="_blank" rel="noopener noreferrer"
               onClick={handleWhatsAppClick}>
                <span className={classes.icon}>
                    <img src={whatsappIcon} alt="whatsapp icon"/>
                </span>
                <span>דברו איתנו בוואטסאפ</span>
            </a>
        </div>
    );
};

export default ContactViaWhatsapp;
