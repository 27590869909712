import React, {useCallback, useEffect, useState} from 'react';
import {Modal, Table, Button, Row, Col, Form, InputGroup} from 'react-bootstrap';
import {useApiService} from "../../context/ApiServiceContext";
import Order, {OrderTask, OrderTaskBody, OrderTaskStatus} from "../../models/Order";
import {isoToFormattedDate} from "../../utils/utils";
import {toast} from "react-toastify";
import EditStatusModal from "./orders/EditStatusModal";
import {useLocation, useNavigate} from "react-router-dom";
import OrderDetailsModal from "../modals/OrderDetailsModal";
import {hideModal, Modals} from "../../utils/modalSlice";
import ConfirmationModal from "../../utils/ConfirmationModal";

export interface AdminOrderTaskBody {
    allowSecondAnchor: boolean;
}

const AdminOrders: React.FC = () => {
    const apiService = useApiService();
    const navigate = useNavigate();
    const location = useLocation();
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const [expandedOrderId, setExpandedOrderId] = useState<string | null>(null);
    const [searchEmail, setSearchEmail] = useState('');
    const [searchId, setSearchId] = useState('');

    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [selectedTask, setSelectedTask] = useState<OrderTask | null>(null);
    const [showEditStatusModal, setShowEditStatusModal] = useState(false);
    const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
    const [deleteTaskModalShow, setDeleteTaskModalShow] = useState(false);
    const [deleteOrderModalShow, setDeleteOrderModalShow] = useState(false);

    const fetchOrders = useCallback(async (page = 1, email: string | null = null, orderId: string | null = null) => {
        try {
            setIsLoading(true);
            let url = `/admin/order/list?page=${page}&per_page=${linksPerPage}`;
            if (email) {
                url += `&email=${encodeURIComponent(email)}`;
            }
            if (orderId) {
                url += `&id=${encodeURIComponent(orderId)}`;
            }
            const res = await apiService.api.get(url);
            if (Array.isArray(res.data)) {
                setOrders(prevOrders => page === 1 ? res.data : [...prevOrders, ...res.data]);
                setHasMorePages(res.data.length === linksPerPage);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setIsLoading(false);
        }
    }, [linksPerPage]);

    useEffect(() => {
        setOrders([])
        fetchOrders(currentPage, searchEmail, searchId);
    }, [currentPage, fetchOrders, searchEmail, searchId]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: "email" | "id") => {
        if (type === 'email') {
            setSearchEmail(e.target.value);
        } else if (type === 'id') {
            setSearchId(e.target.value);
        }
    };

    const handleSearch = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>, type: 'email' | 'id') => {
        e.preventDefault();
        setCurrentPage(1); // Reset to the first page
    };

    const toggleOrderDetails = (orderId: string) => {
        if (expandedOrderId === orderId) {
            setExpandedOrderId(null); // Hide details if clicking the same order
        } else {
            setExpandedOrderId(orderId); // Show details for the clicked order
        }
    };

    const updateOrder = (orderId: string, payload: AdminOrderTaskBody) => {
        return apiService.api.put<OrderTask>(`/admin/order/${orderId}`, payload);
    };

    const toggleAllowSecondAnchor = async (orderId: string, newValue: boolean) => {

        const updatedOrders = orders.map((order) => {
            if (order.id === orderId) {
                // Toggle the allowSecondAnchor value for the matched order
                return {...order, allowSecondAnchor: !order.allowSecondAnchor};
            }
            return order; // Return other orders as-is
        });

        try {
            await updateOrder(orderId, {allowSecondAnchor: newValue}); // Your API call function
            setOrders(updatedOrders)
            toast.success('ההזמנה עודכנה בהצלחה');
        } catch (error) {
            toast.error("בעיה בעת עדכון ההזמנה")
            console.error("Failed to update order", error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    function getStatusInHebrew(status: OrderTaskStatus) {
        const statusMap = {
            [OrderTaskStatus.PENDING]: "ממתין",
            [OrderTaskStatus.PENDING_DATA]: "ממתין לנתונים",
            [OrderTaskStatus.SENT]: "נשלח",
            [OrderTaskStatus.IN_PROGRESS]: "בתהליך",
            [OrderTaskStatus.COMPLETED]: "הושלם",
        };
        return statusMap[status] || status;
    }

    const openEditStatusModal = (order: Order, task: OrderTask) => {
        setSelectedOrder(order);
        setSelectedTask(task);
        setShowEditStatusModal(true);
    };

    const closeEditStatusModal = () => {
        setSelectedOrder(null);
        setSelectedTask(null);
        setShowEditStatusModal(false);
    };

    const deleteTask = (order: Order, task: OrderTask) => {
        if (order.tasks.length == 1) {
            toast.error("אי אפשר למחוק קישור בודד בהזמנה, מחק את ההזמנה כולה")
            return;
        }

        setSelectedOrder(order);
        setSelectedTask(task);
        setDeleteTaskModalShow(true);
    };

    const deleteOrder = (order: Order) => {
        setSelectedOrder(order);
        setDeleteOrderModalShow(true);
    };

    const handleStatusUpdate = (updatedOrder: Order, updatedTask: OrderTask) => {
        const updatedOrders = orders.map((order) => {
            if (order.id === updatedOrder.id) {
                const updatedTasks = order.tasks.map((task) =>
                    task.id === updatedTask.id ? updatedTask : task
                );
                return {...order, tasks: updatedTasks};
            }
            return order;
        });
        setOrders(updatedOrders);
    };

    function openOrder(order: Order, task: OrderTask) {
        setSelectedOrder(order)
        setSelectedTask(task);
        setShowOrderDetailsModal(true)
    }

    const handleDeleteTaskConfirmation = async () => {
        if (selectedOrder && selectedTask) {
            const orderId = selectedOrder.id;
            const taskId = selectedTask.id;

            try {
                await apiService.api.delete<OrderTask>(`/admin/order/${orderId}/task/${taskId}`);
                setOrders(prevState => prevState.map(order => {
                    if (order.id == orderId) {
                        return {
                            ...order,
                            tasks: order.tasks.filter(task => task.id != taskId)
                        }
                    } else {
                        return order;
                    }
                }))
                toast.success('הקישור נמחק בהצלחה');
            } catch (error) {
                toast.error("בעיה בעת מחיקת הקישור")
                console.error("Failed to delete task", error);
            }

        }
        setSelectedOrder(null)
        setSelectedTask(null)
        setDeleteTaskModalShow(false)
    }

    const handleDeleteOrderConfirmation = async () => {
        if (selectedOrder) {
            const orderId = selectedOrder.id;

            try {
                await apiService.api.delete<OrderTask>(`/admin/order/${orderId}`);
                setOrders(prevState => prevState.filter(order => order.id != orderId))
                toast.success('ההזמנה נמחקה בהצלחה');
            } catch (error) {
                toast.error("בעיה בעת מחיקת ההזמנה")
                console.error("Failed to delete task", error);
            }

        }
        setSelectedOrder(null)
        setDeleteOrderModalShow(false)
    }

    async function updateSpecificOrder(selectedOrder: Order, selectedTask: OrderTask) {

        try {
            const response = await apiService.api.get(`/order/${selectedOrder.id}`);
            const updatedTask = response.data.tasks.find((task: OrderTask) => task.id === selectedTask.id);

            if (!updatedTask) return; // Exit if the updated task is not found.

            setOrders(prevState => prevState.map(order =>
                order.id === selectedOrder.id
                    ? {
                        ...order,
                        tasks: order.tasks.map(task =>
                            task.id === selectedTask.id ? { ...updatedTask } : task
                        ),
                    }
                    : order
            ))
        } catch (error) {
            toast.error("בעיה בעת משיכת ההזמנה")
            console.error("Failed to fetch task", error);
        }
    }

    return (
        <>
            <Row className="justify-content-center py-md-5">
                <Col md={4}>
                    <h1 className="mb-2">הזמנות</h1>
                </Col>
                <Col md={6} className="d-flex justify-content-end align-items-center">
                    <Form onSubmit={event => handleSearch(event, 'email')} className="me-4 w-50">
                        <InputGroup>
                            <Form.Control
                                placeholder="חפש לפי אימייל"
                                onChange={event => handleSearchChange(event, 'email')}
                                value={searchEmail}
                            />
                            <Button variant="outline-secondary" onClick={event => handleSearch(event, 'email')}>
                                חפש
                            </Button>
                        </InputGroup>
                    </Form>
                    <Form onSubmit={event => handleSearch(event, 'id')} className="me-4 w-50">
                        <InputGroup>
                            <Form.Control
                                placeholder="חפש לפי מספר הזמנה"
                                onChange={event => handleSearchChange(event, 'id')}
                                value={searchId}
                            />
                            <Button variant="outline-secondary" onClick={event => handleSearch(event, 'id')}>
                                חפש
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md={10}>
                    {isLoading ? (
                        <div>טוען מידע...</div> // Ideally replace with a spinner or skeleton
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>מספר הזמנה</th>
                                <th>אימייל</th>
                                <th>כמות קישורים</th>
                                <th>מחיר כולל</th>
                                <th>תאריך הזמנה</th>
                                <th>2 אנקורים</th>
                                <th>פעולות</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order) => (
                                <>
                                    <tr key={order.id} onClick={() => toggleOrderDetails(order.id)}
                                        style={{cursor: 'pointer'}}>
                                        <td>{order.id}</td>
                                        <td>{order.user?.email ?? 'לא נמצא'}</td>
                                        <td>{`${order.tasks.filter((x) => x.status !== 'pending').length}/${order.tasks.length}`}</td>
                                        <td>{order.totalPrice}₪</td>
                                        <td>{isoToFormattedDate(order.createdAt)}</td>
                                        <td>
                                            {order.allowSecondAnchor ? 'כן' : 'לא'}
                                            <span> </span>
                                            <Button
                                                className="small"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row click
                                                    toggleAllowSecondAnchor(order.id, !order.allowSecondAnchor);
                                                }}
                                                style={{cursor: 'pointer'}}
                                            >
                                                שנה
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                variant='danger'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteOrder(order);
                                                }}
                                            >
                                                מחק
                                            </Button>
                                        </td>
                                    </tr>
                                    {expandedOrderId === order.id && (
                                        <tr>
                                            <td colSpan={7}>
                                                <Table size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>מיקום הקישור</th>
                                                        <th>אנקורים</th>
                                                        <th>אתר מקושר</th>
                                                        <th>תאריך הזמנה</th>
                                                        <th>תאריך ביצוע</th>
                                                        <th>סטטוס</th>
                                                        <th>פעולות</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {order.tasks.map((task, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{task.linkingSite?.hostname || '-'}</td>
                                                            <td>{task.anchor || '-'}</td>
                                                            <td>{task.anchorLink || '-'}</td>
                                                            <td>{isoToFormattedDate(task.orderTime) || '-'}</td>
                                                            <td>{isoToFormattedDate(task.scheduledTime) || '-'}</td>
                                                            <td>{getStatusInHebrew(task.status)}</td>
                                                            <td>
                                                                <Button
                                                                    className="me-2"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        openEditStatusModal(order, task);
                                                                    }}
                                                                >
                                                                    ערוך סטטוס
                                                                </Button>
                                                                <Button
                                                                    variant='outline-primary'
                                                                    className="me-2"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        openOrder(order, task);
                                                                    }}
                                                                >
                                                                    ערוך
                                                                </Button>
                                                                <Button
                                                                    variant='danger'
                                                                    className="me-2"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        deleteTask(order, task);
                                                                    }}
                                                                >
                                                                    מחק
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                            </tbody>
                        </Table>
                    )}
                    <PaginationBar/>
                </Col>
            </Row>
            {selectedOrder && selectedTask && <EditStatusModal
                show={showEditStatusModal}
                order={selectedOrder}
                task={selectedTask}
                onClose={closeEditStatusModal}
                onStatusUpdate={handleStatusUpdate}
            />}

            {selectedOrder && selectedTask && <OrderDetailsModal orderId={selectedOrder!.id}
                                                                 taskId={selectedTask!.id}
                                                                 open={showOrderDetailsModal}
                                                                 handleClose={() => {
                                                                     setShowOrderDetailsModal(false)
                                                                     updateSpecificOrder(selectedOrder, selectedTask)
                                                                     setSelectedTask(null)
                                                                     setSelectedOrder(null)
                                                                 }}/>}

            <ConfirmationModal
                show={deleteTaskModalShow}
                onClose={() => {
                    setDeleteTaskModalShow(false)
                    setSelectedTask(null)
                    setSelectedOrder(null)
                }}
                onConfirm={handleDeleteTaskConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את הקישור?"
            />
            <ConfirmationModal
                show={deleteOrderModalShow}
                onClose={() => {
                    setDeleteOrderModalShow(false)
                    setSelectedOrder(null)
                }}
                onConfirm={handleDeleteOrderConfirmation}
                title="אישור מחיקה"
                message="האם אתה בטוח שאתה רוצה למחוק את הקישור?"
            />
        </>
    );
};

export default AdminOrders;
